.dataTilesWrapper {
  position: relative;
}
.dataTilesClose {
  display: none;
}
.contentWrapper {
  position: relative;
}

.modalContent {
  --border-width: 3px;
  --radius: 15px;

  position: relative;
  border-radius: var(--radius);
  border: var(--border-width) solid transparent;
  width: 100%;
}

.modalContent::before {
  content: ' ';
  position: absolute;
  inset: calc(var(--border-width) * -1);
  z-index: -1;
  border: inherit;
  border-radius: inherit;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 64%) 45%,
    rgba(50, 11, 255, 0%) 96%
  );
  background-origin: border-box;
  -webkit-mask:
    linear-gradient(black, black) content-box,
    linear-gradient(black, black);
  mask: linear-gradient(black, black), linear-gradient(black, black);
  -webkit-mask-clip: content-box, border-box;
  mask-clip: content-box, border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: spin 3s linear infinite;
}

.modalTimer::before {
  background-image: linear-gradient(
    270deg,
    rgba(255, 255, 255, 64%) 45%,
    rgba(50, 11, 255, 0%) 96%
  ) !important;
}

.modalInput {
  position: absolute;
  background: linear-gradient(180deg, rgba(255, 255, 255, 22%) 37%, 61%, transparent);
  border-radius: 15px;
  background-repeat: no-repeat;
}

.dataTilesInput {
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  text-indent: -9999px;
  width: 0;
  z-index: -1;
}

.dataTilesWrapper .dataTilesInput + .dataTilesLabel {
  background: #ff7a00;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  position: relative;
  transition: all 300ms ease;
  width: 20px;
  z-index: 2;
  color: #fff;
  font-weight: bold;
  text-indent: 0.6rem;
  font-size: 1.5rem;
  padding-top: 0;
  line-height: 2rem;
  margin: 0 auto;
  margin-bottom: 1.2rem;
}

.dataTilesLabel.legend {
  background: #ff7a00;
  border-radius: 50%;
  height: 20px;
  transition: all 300ms ease;
  width: 20px;
  z-index: 2;
  line-height: 2rem;
  min-width: 2rem;
  text-align: center;
}
@media (min-width: 1280px) {
  .dataTilesWrapper .dataTilesInput + .dataTilesLabel:before,
  .dataTilesWrapper .dataTilesInput + .dataTilesLabel:after {
    background: #fff;
    content: '';
    cursor: pointer;
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .legend.dataTilesLabel:before,
  .legend.dataTilesLabel:after {
    background: #fff;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 1280px) {
  .dataTilesLabel.legend {
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem;
    padding-top: -0.2rem;
  }
  .dataTilesWrapper .dataTilesLabel:hover {
    transform: none !important;
  }

  .dataTilesWrapper .dataTilesInput + .dataTilesLabel {
    cursor: default !important;
  }
}
.legend.dataTilesLabel:before {
  height: 2px;
  width: 10px;
  left: unset;
  transform: translate(5px, 9px);
}
.legend.dataTilesLabel:after {
  height: 10px;
  width: 2px;
  transform: translate(9px, 5px);
  left: unset;
}
.dataTilesWrapper .dataTilesInput + .dataTilesLabel:before {
  height: 2px;
  width: 50%;
}
.dataTilesWrapper .dataTilesInput + .dataTilesLabel:after {
  height: 50%;
  width: 2px;
}
.dataTilesWrapper .dataTilesInput ~ .dataTiles__Overlay {
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 3;
}
.dataTilesWrapper .dataTilesLabel:hover:not(.legend) {
  transform: rotate(45deg);
}
.modalContent {
  opacity: 1;
  visibility: visible;
}
.dataTilesWrapper.dataTilesBlur figure {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}
.dataTilesWrapper.dataTilesBlur .dataTilesInput {
  display: none;
}
.dataTilesWrapper.dataTilesBlur .dataTilesInput + .dataTilesLabel {
  opacity: 0.5;
  pointer-events: none;
}
.dataTilesWrapper.dataTilesBlur .dataTilesInput:checked {
  display: inline-block;
}
.dataTilesWrapper.dataTilesBlur .dataTilesInput:checked + .dataTilesLabel {
  opacity: 1;
  pointer-events: auto;
}

.dataTilesModal {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1;
}

.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 122, 0, 0.4);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 122, 0, 0);
  }
}

@media (max-width: 768px) {
  .dataTilesWrapper .modalContent {
    bottom: auto !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  .dataTilesWrapper .modalContent .dataTilesClose {
    background: #ff7a00;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 20px;
    right: -5px;
    position: absolute;
    top: -5px;
    transition: all 300ms ease;
    transform: rotate(45deg);
    width: 20px;
    z-index: 5;
  }
  .dataTilesWrapper .modalContent .dataTilesClose:before,
  .dataTilesWrapper .modalContent .dataTilesClose:after {
    background: #fff;
    content: '';
    cursor: pointer;
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .dataTilesWrapper .modalContent .dataTilesClose:before {
    height: 2px;
    width: 40%;
  }
  .dataTilesWrapper .modalContent .dataTilesClose:after {
    height: 40%;
    width: 2px;
  }
}

@supports not (background: paint(something)) {
  .media-object::before {
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 64%) 45%,
      rgba(50, 11, 255, 0%) 96%
    );
  }
}

.media-object:hover::before {
  animation-play-state: paused;
}

@property --angle {
  syntax: '<angle>';
  inherits: true;
  initial-value: 0turn;
}

/* @keyframes spin {
  to {
    --angle: 1turn;
  }
} */
